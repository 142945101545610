<template>
  <div class="page">
    <home-header activeIndex="3" pageTitle="学区分布图"/>
    <div class="wrap">
      <div class="img">
        <img class="xq" :src="require('@/assets/xq1.jpg')" v-if="stage == 1" alt="" />
        <img class="xq" :src="require('@/assets/xq2.jpg')" v-if="stage == 2" alt="" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader";
import Footer from "@/components/footer";

export default {
  name: "Home",
  components: {
    HomeHeader,
    Footer,
  },
  data() {
    return {
      stage: ''
    };
  },
  methods: {
    
  },
  mounted() {
    this.stage = localStorage.getItem("stage")
  },
}
</script>

<style lang="less" scoped>
.wrap{
  width: 1000px;
	// height: 444px;
	background-color: #ffffff;
	box-shadow: 0px 4px 10px 0px 
		rgba(0, 0, 0, 0.1);
	border-radius: 8px;
  margin: -50px auto 30px;
  position: relative;
  z-index: 2;
}
.img{
  text-align: center;
  padding: 24px;
  img{
    width: 100%;
  }
}

</style>